import React, { useEffect } from 'react';
import { getAppleLoginConfig, isLoggedIn } from 'api2';
import LoginBase from 'components/auth/common/LoginBase';
import AuthLoginMain from 'components/auth/login';
import BottomBar from 'components/naver-shopping/common/BottomTabBar';
import BaseLayout from 'components/naver-shopping/common/Layout';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { NextPageAddon } from 'pages/_app';

import { useMessenger } from '@widgets/messenger';

import LoadingManager from 'util/loading_manager';

interface Props {
  bundle_service_id: string;
  nonce: string;
  isAlreadyLoggedIn?: boolean;
}

export const getServerSideProps: GetServerSideProps<Props> = async ({ req, query }) => {
  let isAlreadyLoggedIn = false;
  try {
    if (query?.forceShowLogin !== 'true') {
      const { data } = await isLoggedIn(undefined, { context: req });
      if (data?.isLoggedIn) {
        isAlreadyLoggedIn = true;
      }
    }

    const {
      data: {
        getAppleLoginConfig: { bundle_service_id, nonce },
      },
    } = await getAppleLoginConfig(null, { context: req });
    return {
      props: { bundle_service_id, nonce, isAlreadyLoggedIn },
    };
  } catch (e) {
    return {
      props: { bundle_service_id: '', nonce: '', isAlreadyLoggedIn },
    };
  }
};

const AuthLoginPage: NextPageAddon<Props> = ({ isAlreadyLoggedIn }: Props) => {
  const router = useRouter();

  useEffect(() => {
    if (typeof router.events === 'undefined') {
      return;
    }
    router.events.on('routeChangeComplete', () => {
      LoadingManager.close();
    });
  }, [router]);

  useMessenger();
  return <AuthLoginMain isAlreadyLoggedIn={isAlreadyLoggedIn} />;
};
export default AuthLoginPage;

AuthLoginPage.getLayout = (page: React.ReactElement<Props>) => {
  return (
    <BaseLayout>
      <LoginBase bundle_service_id={page.props.bundle_service_id} nonce={page.props.nonce}>
        {page}
      </LoginBase>
      <BottomBar />
    </BaseLayout>
  );
};
